// import { configureStore } from '@reduxjs/toolkit';
// import { rootReducer } from './rootReducer';

// export const store = configureStore({
//   reducer: rootReducer
// });

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer'; // Import the rootReducer

// Set up the Redux store
const store = configureStore({
  reducer: rootReducer
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  // devTools: process.env.NODE_ENV !== 'production',
});

export default store;
