'use server';

import { AUTHPOST, POST } from '../lib/api';
import { removeSession, setSession } from '../lib/Auth';
export const onSubmitLoginForm = async (values) => {
  try {
    const { token, data } = await AUTHPOST({
      endpoint: 'auth/sign_in',
      body: values
    });
    if (data) {
      await setSession({ token, ...data });
      return data;
    } else {
      throw new Error(
        `${data.status}: ${data.statusText}` || 'Something went wrong'
      );
    }
  } catch (error) {
    throw error;
  }
};

export const logoutHandler = async () => {
  await removeSession();
};

export const onSubmitRegisterForm = async (preValues, values) => {
  const formattedData = {
    RoleId: '3',
    UserName: values.username,
    FirstName: values.firstname,
    LastName: values.lastname,
    Password: values.password,
    CreatedBy: 'User',
    IsActive: true
  };

  try {
    const data = await AUTHPOST({
      endpoint: 'api/users',
      body: formattedData
    });
    return { success: true, message: 'User created successfully!', error: '' };
  } catch (error) {
    return {
      success: false,
      error: error.message || error,
      render: preValues.render + 1
    };
  }
};

export const onSubmitForgotPasswordForm = async (preValues, values) => {
  const { email } = values;
  try {
    const data = await POST({
      endpoint: `api/users/forgotpassword?email=${email}`,
      body: email
    });

    return {
      success: data.success !== false,
      message:
        data.message || 'Please check your email, we have sent you an email.',
      error: data.error || ''
    };
  } catch (error) {
    return {
      success: false,
      error: error.message || error,
      render: preValues.render + 1
    };
  }
};

export const onSubmitResetPasswordForm = async (preValues, values) => {
  const { email, token, Password, ConfirmPassword } = values;
  const formattedValues = {
    ResetType: 'forgot',
    Email: email,
    Token: token,
    Password,
    ConfirmPassword
  };
  try {
    const data = await POST({
      endpoint: `api/users/resetpassword`,
      body: formattedValues
    });

    if (data) {
      return {
        success: true,
        message: 'Password reset successfully.',
        error: ''
      };
    }
  } catch (error) {
    return {
      success: false,
      error: error.message || error,
      render: preValues.render + 1
    };
  }
};
