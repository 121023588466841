import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FullScreenLoader from '../components/common/404Page/loaders/FullScreenLoader';
const AuthLayout = lazy(() => import('../layouts/AuthLayout'));

const NotFound = lazy(() => import('../components/common/404Page/NotFound'));

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<FullScreenLoader />}>
        <Routes>
          <Route path='/*' element={<AuthLayout />}></Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
