import ThemeProvider from './theme/ThemeProvider';
import AppRoutes from './routes/AppRoutes';
import { Provider } from 'react-redux';
import store from './store/store';

function App() {
  return (
    <ThemeProvider>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
