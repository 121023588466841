import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/Auth/Auth';
import AgenciesReducer from './slices/Agency/Agencies';
import ContractReducer from './slices/Contract/Contract';
import AgentReducer from './slices/Agent/Agent';
import filtersLeadReducer from './slices/ContractFilters/ContractFilters';
import AgentLeadsReducer from './slices/AgentLeads/agentleads';
import leadSettingReducer from './slices/PriceSetting/PriceSetting';
import SchedulesReducer from './slices/ContractSchedule/ContractSchedule';

const rootReducer = combineReducers({
  auth: authReducer,
  agencies: AgenciesReducer,
  contracts: ContractReducer,
  agents: AgentReducer,
  filters: filtersLeadReducer,
  schedules: SchedulesReducer,
  agentleads: AgentLeadsReducer,
  leadprices: leadSettingReducer
  // e.g. someOtherSlice: someOtherReducer,
});

export default rootReducer;
