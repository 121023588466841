import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestData } from '../../request';
import { message } from 'antd';
import { getSession } from '../../../lib/Auth';

export const PriceSetting = createAsyncThunk(
  'pricesetting/price',
  async (_, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/price_settings`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

export const PriceSettingOfLeads = createAsyncThunk(
  'pricesetting/price/leads',
  async (leadPriceData, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/price_settings`,
          method: 'PUT',
          data: leadPriceData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// contracts slice
const PriceSettingLeadsSlice = createSlice({
  name: 'pricesetting',
  initialState: {
    pricesetting: null,
    prices: null,
    meta: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // List lead price setting
      .addCase(PriceSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PriceSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.pricesetting = action.payload?.price_setting;
      })
      .addCase(PriceSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch setting leads prices';
      })

      // List price
      .addCase(PriceSettingOfLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PriceSettingOfLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.prices = action.payload?.price_setting;
      })
      .addCase(PriceSettingOfLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch leads prices';
      });
  }
});

export default PriceSettingLeadsSlice.reducer;
