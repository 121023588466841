import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const FullScreenLoader = () => {
  return (
    <div className='fullScreenLoader'>
      <AiOutlineLoading3Quarters className='iconSpin' />
    </div>
  );
};

export default FullScreenLoader;
