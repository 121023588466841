import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestData } from '../../request';
import { message } from 'antd';
import { getSession } from '../../../lib/Auth';

// Create an contract filter
export const createContractFilter = createAsyncThunk(
  'contracts/filters/create',
  async ({ contractId, contractFilterData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts/${contractId}/filters`,
          method: 'POST',
          data: contractFilterData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Filter created successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all contracts filters
export const listContractsFilters = createAsyncThunk(
  'contracts/list/filters',
  async ({ contractId, page, pageSize }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const queryParams = new URLSearchParams({
        page: page.toString(), // Convert to string for URL compatibility
        pageSize: pageSize.toString() // Convert to string for URL compatibility
      }).toString();
      const data = await requestData(
        {
          url: `api/v1/contracts/${contractId}/filters?${queryParams}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all contracts filters search
export const listContractsFiltersSearch = createAsyncThunk(
  'contracts/list/filters/search',
  async ({ contractId, name }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts/${contractId}/filters?search=${name}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all contracts filters
export const listContractsFiltersLeads = createAsyncThunk(
  'contracts/list/filters/leads',
  async (_, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: 'api/v1/leads_fields',
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all contracts filters Values
export const listContractsFiltersLeadsValues = createAsyncThunk(
  'contracts/list/filters/leads/values',
  async (id, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/leads_fields/${id}/filter_values`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Update an contract
export const updateContractFilter = createAsyncThunk(
  'contracts/filters/update',
  async ({ contractId, id, contractFiltersData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts/${contractId}/filters/${id}`,
          method: 'PUT',
          data: contractFiltersData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Filter updated successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Delete an contracts
export const deleteContractFilter = createAsyncThunk(
  'contracts/Filter/delete',
  async ({ contractid, id }, { rejectWithValue }) => {
    try {
      const token = getSession();
      await requestData(
        {
          url: `api/v1/contracts/${contractid}/filters/${id}`,
          method: 'DELETE',
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Filter deleted successfully');
      return id;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// contracts slice
const contractsFiltersSlice = createSlice({
  name: 'filters',
  initialState: {
    contractsfilters: [],
    meta: null,
    filter: null,
    leads: [],
    values: [],
    loading: false,
    error: null
  },
  reducers: {
    ContractFilterCreate: (state, action) => {
      state.filter = action.payload.filter;
    }
  },
  extraReducers: (builder) => {
    builder
      // Create contract filters
      .addCase(createContractFilter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createContractFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.filter = action.payload.filter;
        state.contractsfilters = [
          action.payload.filter,
          ...state.contractsfilters
        ];
      })

      .addCase(createContractFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create contract';
      })

      // List contracts filters
      .addCase(listContractsFilters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsFilters.fulfilled, (state, action) => {
        state.loading = false;
        state.contractsfilters = action.payload?.filters;
        state.meta = action.payload?.meta;
      })
      .addCase(listContractsFilters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch contract filters';
      })

      // List contracts search
      .addCase(listContractsFiltersSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsFiltersSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.contractsfilters = action.payload?.filters;
        state.meta = action.payload?.meta;
      })
      .addCase(listContractsFiltersSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch contract filters';
      })

      // List contracts of leads
      .addCase(listContractsFiltersLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsFiltersLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.leads = action.payload?.fields;
      })
      .addCase(listContractsFiltersLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch leads';
      })

      // List contracts of leads values
      .addCase(listContractsFiltersLeadsValues.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsFiltersLeadsValues.fulfilled, (state, action) => {
        state.loading = false;
        state.values = action.payload?.values;
      })
      .addCase(listContractsFiltersLeadsValues.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch leads values';
      })

      // Update Contract filter
      .addCase(updateContractFilter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContractFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.contractsfilters = state.contractsfilters.map((filter) =>
          filter.id === action.payload.filter?.id
            ? { ...filter, ...action.payload.filter }
            : filter
        );
        state.filter = action.payload.filter;
      })
      .addCase(updateContractFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update contract filter';
      })

      // Delete contract
      .addCase(deleteContractFilter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteContractFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.contractsfilters = state.contractsfilters.filter(
          (filter) => filter.id !== action.payload
        );
      })
      .addCase(deleteContractFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to delete contract filter';
      });
  }
});

export const { ContractFilterCreate } = contractsFiltersSlice.actions;
export default contractsFiltersSlice.reducer;
